import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import style from "./NavBar.module.scss";
import { NavLink } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import {
  RiLinkedinLine,
  RiInstagramLine,
  RiGithubLine,
  RiFacebookLine,
} from "react-icons/ri";

const NavBar = ({ dimensions }) => {
  const [openNav, setOpenNav] = useState(false);
  const [nav, setNav] = useState(false);

  useEffect(() => {
    if (!openNav) {
      const scrollValue = dimensions >= 770 ? 100 : 10;

      const changeBackground = () => {
        if (window.scrollY >= scrollValue) {
          setNav(true);
        } else {
          setNav(false);
        }
      };

      window.addEventListener("scroll", changeBackground);

      return () => {
        window.removeEventListener("scroll", changeBackground);
      };
    }
  }, [dimensions, openNav]);

  const handleToggle = () => {
    setOpenNav(!openNav);
  };

  const links = [
    { name: "HOME", to: "home" },
    { name: "EXPERIENCE", to: "experience" },
    { name: "PROJECTS", to: "projects" },
    { name: "SKILLS", to: "skills" },
    { name: "CONTACT", to: "contact" },
  ];

  const linksIcons = [
    {
      href: "https://www.linkedin.com/in/david-heredia-91625b24/",
      icon: <RiLinkedinLine className={style.icon} />,
    },
    {
      href: "https://github.com/davidheredia25",
      icon: <RiGithubLine className={style.icon} />,
    },
    {
      href: "https://www.instagram.com/deividheredia/",
      icon: <RiInstagramLine className={style.icon} />,
    },
    {
      href: "https://www.facebook.com/davis.heredia/",
      icon: <RiFacebookLine className={style.icon} />,
    },
  ];

  return (
    <div>
      <Navbar
        className={nav || openNav ? style.color : style.trasp}
        collapseOnSelect
        expand={dimensions <= 1000 ? false : "lg"}
        fixed="top"
        variant="dark"
        onToggle={handleToggle}
      >
        <Container fluid className={nav ? style.minCntCon : style.cntCon}>
          <Link
            className={style.navLinkDH}
            to="home"
            smooth={true}
            duration={500}
          >
            DavidHeredia
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />{" "}
          <Navbar.Collapse
            className={style.navCss}
            id="responsive-navbar-nav"
            in={openNav}
          >
            <Nav className={style.ctnNav1}>
              {links.map((link) => (
                <Link
                  onClick={() => setOpenNav(false)}
                  key={link.name}
                  className={style.navLink}
                  to={link.to}
                  smooth={true}
                  duration={500}
                >
                  {link.name}
                </Link>
              ))}

              <NavLink
                to="./download-curriculum-vitae"
                target="_blank"
                className={style.navLink}
              >
                {" "}
                DOWNLOAD CV
              </NavLink>
            </Nav>

            <Nav className={style.ctnIcons}>
              {linksIcons.map((link) => (
                <a
                  className={style.navLink1}
                  href={link.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.icon}
                </a>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
